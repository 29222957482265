import * as React from 'react'
import NavHeader from '../components/navHeader'
import PropTypes from "prop-types"
import SiteFooter from '../components/footer'
import PageTitle from '../components/pageTitle'
import { Link } from 'gatsby'
import * as eGovernmentStyle from '../styles/eGovernment.module.scss'

const EGovernment = () => {
  return (
    <main>
    <NavHeader/>
    <PageTitle pageTitle="eGovernment"/>
        <div className="mainContainer">
          <div className="egovernment-container">
            <section className={eGovernmentStyle.egDescription}>
              <div className="section-container">
                <h1>Portfólio riešení v oblasti eGovernmentu</h1>
                <p>
                  Spoločnosť Globaltel, a.s., sa zaoberá tvorbou komplexných elektronických komunikačných riešení, vrátane
                  elektronickej bezpečnosti a systémovej integrácie. Podieľa sa na dodávke riešenia pre Ústredný portál
                  verejne správy a jeho spoločné moduly, náhradu papierových kolkov elektronickými a systémy pre otvorenú
                  komunikáciu s verejnosťou.
                </p>
              </div>
            </section>
            <section className={eGovernmentStyle.egButtons}>
              <div className="section-container">
                <Link to="/eGovernment/egovernment-doveryhodne-dorucovanie">
                  <div className={eGovernmentStyle.egButton}>
                    <div className={eGovernmentStyle.egButtonIcon} id={eGovernmentStyle.deliveryIcon}></div>
                    <span>Systém pre dôveryhodné doručovanie</span>
                  </div>
                </Link>
                <Link to="/eGovernment/egovernment-platobny-system">
                  <div className={eGovernmentStyle.egButton}>
                    <div className={eGovernmentStyle.egButtonIcon} id={eGovernmentStyle.paymentIcon}></div>
                    <span>Jednotný platobný systém</span>
                  </div>
                </Link>
                <Link to="/eGovernment/egovernment-dotacie">
                  <div className={eGovernmentStyle.egButton}>
                    <div className={eGovernmentStyle.egButtonIcon} id={eGovernmentStyle.grantIcon}></div>
                    <span>Dotácie</span>
                  </div>
                </Link>
              </div>
            </section>
          </div>
        </div>
    <SiteFooter/>
    </main>
  )
}

EGovernment.propTypes = {
  children: PropTypes.node.isRequired,
}


export default EGovernment